<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{ errors }}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="firstname2">Tanggal (dari)</label>
                                <Calendar :showIcon="true" :showButtonBar="true" v-model="dateFrom"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="lastname2">Tanggal (sampai)</label>
                                <Calendar :showIcon="true" :showButtonBar="true" v-model="dateTo"></Calendar>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-6">
                                <label for="address">Tipe Visit</label>
                                <Dropdown v-model="visitType" :options="visitTypeOption" optionLabel="name"
                                    placeholder="Select" />
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1"
                        @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" 
                    @sort="onSort($event)">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <!-- <Column field="number" header="No." :style="{ width: '20px' }">
                        <template #body="slotProps">
                            {{ slotProps.data.number }}
                        </template>
                    </Column> -->
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{ slotProps.data.username }}
                        </template>
                    </Column>
                    <Column field="nama" header="Nama">
                        <template #body="slotProps">
                            {{ slotProps.data.names }}
                        </template>
                    </Column>
                    <Column field="visit_in_type" header="Tipe Visit">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_in_type }}
                        </template>
                    </Column>
                    <Column field="visit_in_date" header="Tanggal Checkin">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_in_date }}
                        </template>
                    </Column>
                    <Column field="visit_in_time" header="Waktu Checkin">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_in_time }}
                        </template>
                    </Column>
                    <Column field="visit_in_gps_address" header="Alamat GPS Checkin">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_in_gps_address }}
                        </template>
                    </Column>
                    <Column field="visit_in_url_foto" header="Photo Checkin">
                        <template #body="slotProps">
                            
                            <img :src="slotProps.data.visit_in_url_foto"  class="product-image" style="width:100px;"/>
                        </template>
                    </Column>
                    <Column field="visit_out_time" header="Waktu Checkout">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_out_time }}
                        </template>
                    </Column>
                    <Column field="visit_out_gps_address" header="Alamat GPS Checkout">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_out_gps_address }}
                        </template>
                    </Column>
                    <Column field="visit_out_type" header="Status Checkout">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_out_type }}
                        </template>
                    </Column>
                    <Column field="visit_out_note" header="Note Checkout">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_out_note }}
                        </template>
                    </Column>
                    <Column field="visit_out_not_order" header="Not Order Checkout">
                        <template #body="slotProps">
                            {{ slotProps.data.visit_out_not_order }}
                        </template>
                    </Column>
                    <Column field="is_by_phone" header="By Phone?">
                        <template #body="slotProps">
                            {{ slotProps.data.is_by_phone }}
                        </template>
                    </Column>
                    <Column field="by_phone_nohp" header="By Phone No HP">
                        <template #body="slotProps">
                            {{ slotProps.data.by_phone_nohp }}
                        </template>
                    </Column>
                    <Column field="by_phone_note" header="By Phone Note">
                        <template #body="slotProps">
                            {{ slotProps.data.by_phone_note }}
                        </template>
                    </Column>
                    <Column field="is_tunda_order" header="Tunda Order?">
                        <template #body="slotProps">
                            {{ slotProps.data.is_tunda_order }}
                        </template>
                    </Column>
                    <Column field="tunda_start_time" header="Tunda Start">
                        <template #body="slotProps">
                            {{ slotProps.data.tunda_start_time }}
                        </template>
                    </Column>
                    <Column field="tunda_end_time" header="Tunda End">
                        <template #body="slotProps">
                            {{ slotProps.data.tunda_end_time }}
                        </template>
                    </Column>
                    <Column field="is_offline" header="Offline?">
                        <template #body="slotProps">
                            {{ slotProps.data.is_offline }}
                        </template>
                    </Column>
                    <Column field="customer_name" header="Customer">
                        <template #body="slotProps">
                            {{ slotProps.data.customer_name }}
                        </template>
                    </Column>
                    <Column field="label_lokasi" header="Lokasi">
                        <template #body="slotProps">
                            {{ slotProps.data.label_lokasi }}
                        </template>
                    </Column>
                    <Column field="delivery_address1" header="Alamat Pengiriman 1">
                        <template #body="slotProps">
                            {{ slotProps.data.delivery_address1 }}
                        </template>
                    </Column>
                    <Column field="delivery_address2" header="Alamat Pengiriman 2">
                        <template #body="slotProps">
                            {{ slotProps.data.delivery_address2 }}
                        </template>
                    </Column>
                    <Column field="employee_name" header="Nama Employee">
                        <template #body="slotProps">
                            {{ slotProps.data.employee_name }}
                        </template>
                    </Column>
                    <!-- <Column field="level_nama" header="Level">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.level !== null">
                                {{ slotProps.data.level.level_nama }}
                            </div>
                        </template>
                    </Column> -->
                    
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{ totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount"
                    :rowsPerPageOptions="[10, 20, 30]">
                    <template>
                        {{ totalItemsCount }}
                    </template>
                </Paginator>

                
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingDropdownLevelAdd: false,
            loadingDropdownLevelEdit: false,
            loadingDropdownLevelFilter: false,
            loadingExcel: false,
            loadingCsv: false,

            //form
            dateFrom: '',
            dateTo: '',
            visitType: '',

            // dataDropdown
            dataDropdownLevelAdd: null,
            dataDropdownLevelEdit: null,
            dataDropdownLevelFilter: null,
            visitTypeOption: [
                { name: 'ALL', code: '' },
                { name: 'RUTIN', code: 'RUTIN' },
                { name: 'EXTRA', code: 'EXTRA' },
            ],

            // addNew
            forms: {
                username: null,
                name: null,
                level_id: null,
            },

            // edit
            item: {},
            editItemDialog: false,

            // filter
            filters: {
                level_id: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed: {
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },

        formatDatePost(value) {
            if (value) {
                return moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
            }
        },
        // DATATABLE
        getDataTable() {

            this.loading = true;
            this.page = (this.offset / this.rows) + 1;

            let diffDate = 0;
            if (this.dateFrom != '' || this.dateTo != '') {
                let dateA = new Date(this.formatDatePost(this.dateFrom));
                let dateB = new Date(this.formatDatePost(this.dateTo));
                let diffTime = dateB.getTime() - dateA.getTime();            
                diffDate = Math.round(diffTime / (1000 * 3600 * 24));
            }

            if (diffDate <= 30) {
                this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/visit',
                params: {
                    "search": this.search,
                    "per_page": this.rows,
                    "page": this.page,
                    "order_by": this.field,
                    "sort_by": this.sort,
                    "visit_type": this.visitType.code,
                    "date_from": this.formatDatePost(this.dateFrom),
                    "date_to": this.formatDatePost(this.dateTo),
                }
            })
                .then(res => {
                    this.data = res.data.data.data;
                    
                    this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                    
                    this.totalItemsCount = res.data.data.total;
                    this.rows = parseInt(res.data.data.per_page);
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
            }
            
            

        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext) {

            if (ext == 'xlsx') {
                this.loadingExcel = true;
            } else if (ext == 'csv') {
                this.loadingCsv = true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/visit/export',
                responseType: 'blob',
                params: {
                    "ext": ext,
                    "visit_type": this.visitType.code,
                    "date_from": this.formatDatePost(this.dateFrom),
                    "date_to": this.formatDatePost(this.dateTo),
                }
            })
                .then(response => {
                    let fileUrl = window.URL.createObjectURL(response.data);
                    let fileLink = document.createElement('a');

                    fileLink.href = fileUrl;

                    fileLink.setAttribute('download', 'Visit Export.' + ext);

                    document.body.appendChild(fileLink)

                    fileLink.click();

                    if (ext == 'xlsx') {
                        this.loadingExcel = false;
                    } else if (ext == 'csv') {
                        this.loadingCsv = false;
                    }

                })
                .catch((err) => {
                    console.log(err);

                    if (ext == 'xlsx') {
                        this.loadingExcel = false;
                    } else if (ext == 'csv') {
                        this.loadingCsv = false;
                    }
                });
        },
    }
}
</script>